import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { GetStaticPathsResult, GetStaticPropsContext } from 'next';
import { OperationVariables } from '@apollo/client';
import dynamic from 'next/dynamic';

import { addApolloState, initializeApollo } from '../../src/graphql/apollo';
import constants from '../../src/utils/constants';
import {
  Book,
  CurrentProductsQuery,
  GetBestSellersQuery,
  GetImportantProductQuery,
  GetPrePublicationQuery,
} from '../../src/graphqlTypes';
import {
  getCategoryFromSection,
  getSectionNameFromRoute,
  parseCategory,
} from '../../src/utils/utils';
import {
  currentProducts,
  getBestSellers,
  getImportantProduct,
  getPrePublication,
} from '../../src/graphql/queries';
import useSaveSectionScroll from '../../src/hooks/useSaveSectionScroll';
import useFiltersAndOrder from '../../src/hooks/useFiltersAndOrder';
import { getDataFromStorage, NameStorage } from '../../src/utils/services/localStorage.service';
import BooksBestSellers from '../../src/components/common/sectionPagination/BooksBestSellers';

const Listing = dynamic(() => import('../../src/components/common/sectionPagination/Listing'));
const MainLayout = dynamic(() => import('../../src/layouts/MainLayout/MainLayout'));
const PageContent = dynamic(() => import('../../src/layouts/PageContent'));

const ButtonAreaOfInterestSection = dynamic(
  () => import('../../src/components/common/sectionPagination/ButtonAreaOfInterestSection'),
);

const {
  initialPagination: { skip, limit },
  allProductCategoriesFull,
  sectionSpanishWithAreasOfInterest,
} = constants;

interface SectionPageProps {
  type: string;
  title: string;
  section: string;
  results: string;
  productTypeTitle: string;
  listCurrentProducts: Book[];
  setSearching: (value?: string) => void;
  category: string;
  listProductsBanners: Book[];
  listImportantProducts: Book[];
  listBestSellers: Book[];
  listPrePublications: Book[];
}

interface SectionPageState {
  type?: string;
  productsWithBanner?: Book[];
  category?: string;
  expanded?: boolean;
  productTypeTitle?: string;
}

const SectionPage = ({
  type,
  title,
  section,
  results,
  category,
  listCurrentProducts,
  listBestSellers,
  listImportantProducts,
  listPrePublications,
  productTypeTitle,
}: SectionPageProps): JSX.Element => {
  const { changeFilterArea } = useFiltersAndOrder();
  useSaveSectionScroll();
  const [state, setState] = useState<SectionPageState>({
    type,
  });
  const condicionArea = getDataFromStorage<string>(NameStorage.areaOfInterest);

  useEffect(() => {
    if (condicionArea && sectionSpanishWithAreasOfInterest.includes(section)) {
      changeFilterArea(Number(condicionArea), true);
    }
  }, []);

  useEffect(() => {
    if (type) {
      setState((prevState) => ({ ...prevState, type }));
    }
  }, [type]);

  return (
    <>
      {title && <Head>{title && <title>{title}</title>}</Head>}
      <Head>
        <meta name="Librería Axon" content={title} />
      </Head>

      <MainLayout category={category}>
        {category !== 'magazines' && results !== 'resultados-revistas' ? (
          <PageContent
            key="pageContent"
            id={category}
            className="listOfResults"
            category={category}
            listProducts={listCurrentProducts}
          >
            <>
              <ButtonAreaOfInterestSection
                productTypeTitle={productTypeTitle}
                hasIconRight={!!condicionArea}
                category={category}
              />

              <BooksBestSellers
                category={category}
                listBestSellers={listBestSellers}
                listImportantProducts={listImportantProducts}
                listPrePublications={listPrePublications}
                productTypeTitle={productTypeTitle}
              />

              <Listing
                title={title}
                type={state?.type || 'all'}
                category={category}
                listCurrentProducts={listCurrentProducts}
              />
            </>
          </PageContent>
        ) : null}
      </MainLayout>
    </>
  );
};

export const getStaticPaths = (): GetStaticPathsResult => {
  return {
    paths: [],
    fallback: true,
  };
};

export const getStaticProps = async (
  context: GetStaticPropsContext,
): Promise<Record<string, unknown>> => {
  const section = context.params.section.toString();
  const listSections = allProductCategoriesFull.filter((s) => s !== 'revistas');

  if (!listSections.includes(section)) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }

  const apolloClient = initializeApollo();
  const product_type = getCategoryFromSection(section);
  const title = getSectionNameFromRoute(section);
  const productTypeTitle = parseCategory(product_type);

  const variables: OperationVariables = {
    skip,
    limit,
    area_prox: null,
    areas_of_interest: [null],
    lang: null,
    order: -1,
    product_type: [product_type],
    prox: false,
    rangePrice: null,
    sort: 'book.edition.year',
    tags: null,
    type: 'all',
  };

  const listProductsOrderByClicks = ['model', 'material', 'poster'];

  if (listProductsOrderByClicks.includes(product_type)) {
    variables.sort = 'clicks';
  }

  const asyncGetImportantProduct = apolloClient.query<GetImportantProductQuery>({
    query: getImportantProduct,
    variables: { product_type },
  });

  const getBestsellers = apolloClient.query<GetBestSellersQuery>({
    query: getBestSellers,
    variables: { product_type, areas_of_interest: [] },
  });

  const getPrepublication = apolloClient.query<GetPrePublicationQuery>({
    query: getPrePublication,
    variables: { product_type },
  });

  const getCurrentProducts = apolloClient.query<CurrentProductsQuery>({
    query: currentProducts,
    variables,
    fetchPolicy: 'network-only',
  });

  const [
    {
      data: { books: listCurrentProducts },
    },

    {
      data: { getBestSellers: listBestSellers },
    },
    {
      data: { getImportantProduct: listImportantProducts },
    },
    {
      data: { getPrePublication: listPrePublications },
    },
  ] = await Promise.all([
    getCurrentProducts,
    getBestsellers,
    asyncGetImportantProduct,
    getPrepublication,
  ]);

  return addApolloState(apolloClient, {
    props: {
      section,
      title,
      listCurrentProducts,
      listImportantProducts,
      listBestSellers,
      listPrePublications,
      productTypeTitle,
      areaOfInterest: 'null',
      category: product_type,
    },
    revalidate: 3600,
  });
};

export default SectionPage;
